import { render, staticRenderFns } from "./ContentTotal.vue?vue&type=template&id=3c435e41&scoped=true&"
import script from "./ContentTotal.vue?vue&type=script&lang=js&"
export * from "./ContentTotal.vue?vue&type=script&lang=js&"
import style0 from "./ContentTotal.vue?vue&type=style&index=0&id=3c435e41&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c435e41",
  null
  
)

export default component.exports